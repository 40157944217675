import Zdog from "zdog";
import { Numbers } from "@mitevpi/algos";

export function CreateBuildings(
  illustration,
  number,
  width = [3, 14],
  height = [5, 25],
  x = 0,
  y = 0,
  z = 0,
  color = "lightgray"
) {
  const building = new Zdog.Rect({
    addTo: illustration,
    width: 15,
    height: 35,
    translate: { x: -50, y: -10 },
    stroke: 5,
    fill: true,
    color
  });
  const rNumber = Numbers.randomInt(number[0], number[1]);
  [...Array(rNumber)].map((_, i) => {
    building.copy({
      height: Numbers.randomInt(height[0], height[1]),
      width: Numbers.randomInt(width[0], width[1]),
      translate: {
        x: Numbers.randomInt(x[0], x[1]),
        y: Numbers.randomInt(y[0], y[1]),
        z: Numbers.randomInt(z[0], z[1]),
      },
    });
  });
}
