import Zdog from "zdog";
import { Numbers } from "@mitevpi/algos";

export function CreateBackgroundGroups(background) {
  const backgroundGroups = [];
  const rArray = [-Numbers.randomInt(10, 50), 0, Numbers.randomInt(10, 50)];
  [...rArray].map((d, i) => {
    backgroundGroups.push(
      new Zdog.Group({
        addTo: background,
        translate: { z: rArray[i] },
      })
    );
    return true;
  });
  return backgroundGroups;
}
export function CreateBackgroundCircles(background, backgroundGroups, colors) {
  const rDiameter = Numbers.randomInt(90, 170);
  const bgCircle = new Zdog.Ellipse({
    diameter: rDiameter,
    addTo: backgroundGroups[0],
    translate: { y: Numbers.randomInt(-35, -10) },
    color: colors[0],
    stroke: Numbers.randomInt(20, 30),
    fill: true,
  });

  // amber circle
  bgCircle.copy({
    addTo: backgroundGroups[1],
    diameter: rDiameter * Numbers.randomFloat(0.4, 0.85),
    translate: { y: Numbers.randomInt(-25, -10) },
    color: colors[1],
  });

  // sun
  const sun = new Zdog.Shape({
    addTo: background,
    translate: { y: Numbers.randomInt(-40, -15), z: 40 },
    stroke: Numbers.randomInt(20, 60),
    color: "white",
  });
}

export function CreateBackground(illustration, layerSpace, colors) {
  // BACKGROUND
  const background = new Zdog.Shape({
    translate: { z: layerSpace, y: -20 },
    visible: false,
    addTo: illustration,
  });

  const backgroundGroups = CreateBackgroundGroups(background);
  CreateBackgroundCircles(background, backgroundGroups, colors);

  const bgStripe = new Zdog.Rect({
    width: 200,
    height: 44,
    addTo: backgroundGroups[0],
    translate: { y: -40 },
    color: colors[0],
    stroke: 12,
    fill: true,
  });

  // amber stripe
  bgStripe.copy({
    addTo: backgroundGroups[1],
    translate: { y: -8 },
    color: colors[1],
  });

  // green bg stripe
  bgStripe.copy({
    addTo: backgroundGroups[2],
    height: 60,
    translate: { y: 40 },
    color: colors[2],
  });
}
