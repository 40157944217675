import Zdog from "zdog";
import { Numbers } from "@mitevpi/algos";

/**
 * Create a single tree object.
 * @param groupOptions ZDog GroupOptions object
 * @param options ZDog Options object
 */
export function CreateTree(groupOptions, options) {
  options = Zdog.extend(options, groupOptions);
  const heightRandomFactor = Numbers.randomInt(1, 10);
  const crownPoint = { x: 0, y: -options.height / heightRandomFactor };

  // create the tree crown
  const treeOptionsCrown = Zdog.extend(
    {
      path: [crownPoint],
      fill: true,
      stroke: options.customStrokeCrown || 30,
    },
    options
  );

  // create tree stem
  const treeOptionsStem = Zdog.extend(
    {
      path: [crownPoint, { x: 0, y: options.height }],
      fill: true,
      stroke: options.customStrokeTrunk || 5,
    },
    options
  );

  new Zdog.Shape(treeOptionsCrown);
  new Zdog.Shape(treeOptionsStem);
}
export function CreateRandomTrees(
  groupOptions,
  number = [4, 8],
  width = [10, 16],
  height = [50, 90],
  x = [-110, 110],
  y = [10, 50],
  z = [-10, 10],
  stroke = [8, 50]
) {
  const rNumber = Numbers.randomInt(number[0], number[1]);
  [...Array(rNumber)].map((_, i) => {
    const customStrokeTrunk = Numbers.randomInt(2, 10);

    CreateTree(groupOptions, {
      width: Numbers.randomInt(width[0], width[1]),
      height: 130,
      customStrokeTrunk,
      customStrokeCrown: Numbers.randomInt(stroke[0], stroke[1]) + customStrokeTrunk,
      translate: {
        x: Numbers.randomInt(x[0], x[1]),
        // y: Numbers.randomInt(y[0], y[1]),
        z: Numbers.randomInt(z[0], z[1]),
      },
    });
    return true;
  });
}
