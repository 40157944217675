import { Numbers } from "@mitevpi/algos";
import Zdog from "zdog";
import { CreateRandomTrees } from "./Tree";
import { CreateTerrain } from "./Terrain";
import { CreateBuildings } from "./Buildings";
import { CreateBackground } from "./Background";

/**
 * Create a naturescape layer.
 * @param illustration The ZDog illustration object to add this layer to.
 * @param color The color of the buildings.
 * @param layerSpace The ZDog layerSpace ID.
 * @param zTranslate The offset in the Z direction (Depth).
 * @param scale General modifier to height scale so that layers can appear
 * staggered and visible when viewed head-on.
 */
export function CreateNatureLayer(
  illustration,
  color,
  layerSpace,
  zTranslate = Numbers.randomFloat(-2, 2),
  scale = 0
) {
  const path = CreateTerrain([25, 85], scale);
  const layerA = new Zdog.Shape({
    path,
    addTo: illustration,
    translate: { z: layerSpace * zTranslate },
    color,
    stroke: 40, // 48
    fill: true,
  });

  const treeOptions = {
    color,
    addTo: layerA,
  };

  CreateRandomTrees(treeOptions, [1, 8]);
}

/**
 * Create a cityscape layer.
 * @param illustration The ZDog illustration object to add this layer to.
 * @param color The color of the buildings.
 * @param layerSpace The ZDog layerSpace ID.
 * @param zTranslate The offset in the Z direction (Depth).
 */
export function CreateCityLayer(
  illustration,
  color,
  layerSpace,
  zTranslate = Numbers.randomFloat(-2, 2),
) {
  const buildingLayer = new Zdog.Anchor({
    addTo: illustration,
    translate: { z: layerSpace * zTranslate },
  });

  CreateBuildings(
    buildingLayer,
    [4, 14],
    [7, 40],
    [20, 90],
    [-92, 92],
    [-10, -20],
    [-10, 5],
    color
  );
}

/**
 * Create the background layers.
 * @param illustration The ZDog illustration object to add this layer to.
 * @param colors An array of three colors to use for the background layers.
 * @param layerSpace The ZDog layerSpace ID.
 * @param zTranslate The offset in the Z direction (Depth).
 */
export function CreateBackGroundLayer(
  illustration,
  colors,
  layerSpace,
  zTranslate = Numbers.randomFloat(-2, 2),
) {
  const buildingLayer = new Zdog.Anchor({
    addTo: illustration,
    translate: { z: layerSpace * zTranslate },
  });

  CreateBackground(buildingLayer, layerSpace, colors);
}
