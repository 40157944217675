<template>
  <div>
    <canvas class="illo" :width="canvasWidth" :height="canvasHeight" />
  </div>
</template>

<script>
import Zdog from "zdog";
import {
  CreateNatureLayer,
  CreateCityLayer,
  CreateBackGroundLayer,
} from "../lib/Builder";

export default {
  name: "Splash",
  data: () => ({
    white: "#F4F5F6",
    screenWidth: 1100,
    layerSpace: 56,
    background: null,
    backgroundGroups: [],
    midBackground: null,
    midground: null,
    midForeground: null,
    illustration: null,
  }),
  computed: {
    canvasWidth() {
      return this.screen ? 1000 : 500;
    },
    canvasHeight() {
      return this.screen ? 800 : 480;
    },
    zoom() {
      let zoom = 1;
      if (this.smallPhone) {
        zoom = 0.5;
      } else if (this.largePhone) {
        zoom = 1.1;
      } else if (this.screen) {
        zoom = 1.7;
      } else {
        zoom = 1;
      }

      return zoom;
    },
    hour() {
      return new Date().getHours();
    },
    midnight() {
      return this.hour < 17 ? "#172A54" : "#313";
    },
    darkGreen() {
      return this.hour < 17 ? "#198749" : "#525";
    },
    cerulean() {
      return this.hour < 17 ? "#C5E7EA" : "#936";
    },
    amber() {
      return this.hour < 17 ? "#F9EE34" : "#FA6";
    },
    lightGreen() {
      return this.hour < 17 ? "#80C341" : "#D65";
    },
    deviceHeight() {
      return window.innerHeight;
    },
    smallPhone() {
      return this.deviceHeight < 600;
    },
    largePhone() {
      return this.deviceHeight > 800 && this.deviceHeight < 1100;
    },
    screen() {
      return window.innerWidth > 1100;
    },
  },
  mounted() {
    this.CreateLandscape();
    this.RunAnimation();
  },
  methods: {
    CreateLandscape() {
      const self = this;
      const illoElem = document.querySelector(".illo");

      this.isSpinning = true;
      this.illustration = new Zdog.Illustration({
        element: illoElem,
        zoom: this.zoom,
        dragRotate: true,
        onDragStart() {
          self.isSpinning = false;
        },
      });

      CreateNatureLayer(this.illustration, this.midnight, this.layerSpace, 2, 150);
      CreateNatureLayer(this.illustration, this.darkGreen, this.layerSpace, 1, 100);
      CreateNatureLayer(this.illustration, this.cerulean, this.layerSpace, 0, 50);
      CreateNatureLayer(this.illustration, this.lightGreen, this.layerSpace, -1, 15);
      CreateCityLayer(this.illustration, "lightgray", this.layerSpace, -2);
      CreateBackGroundLayer(
        this.illustration,
        [this.cerulean, this.amber, this.midnight],
        this.layerSpace,
        -4.5
      );
    },
    RunAnimation() {
      const self = this;
      const { TAU } = Zdog;
      let t = 0;
      const tSpeed = 1 / 240;
      function animate() {
        // update
        if (self.isSpinning) {
          t += tSpeed;
          const theta = Zdog.easeInOut(t % 1) * TAU;
          const delta = (TAU * -3) / 64;
          self.illustration.rotate.y = Math.sin(theta) * delta;
          self.illustration.rotate.x = (Math.cos(theta) * -0.5 + 0.5) * delta;
        }

        self.illustration.updateRenderGraph();
        requestAnimationFrame(animate);
      }

      animate();
    },
  },
};
</script>

<style scoped>
html {
  height: 100%;
}
canvas {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
