import { Numbers } from "@mitevpi/algos";

/**
 * Create a shape/path which represents ground terrain.
 * @param range A min/max input to determine a random number of "points"
 * which make up the shape path of the terrain. ex. [10, 60]
 * @param scale Modifiers to height
 * @returns {[Object]} An array of X,Y points to use in creating a ZDog shape.
 */
export function CreateTerrain(range, scale) {
  const xBound = 96;
  const yBoundLow = 140;
  const yBoundHigh = Numbers.randomInt(-10, scale);
  const path = [];

  // set a starting x and y point from the boundaries
  let numberX = -xBound;
  let numberY = yBoundHigh;

  // get a random number from the bounds input into the function
  const controlPoints = Numbers.randomInt(range[0], range[1]);
  // console.log("CONTROL POINTS", controlPoints);

  // create an array the size of the random number and iterate over it
  [...Array(controlPoints)].map((_, i) => {
    const variance = 20;
    if (numberX > xBound) {
      numberX = xBound;
    }
    if (numberY > yBoundLow) {
      numberY = yBoundLow - Numbers.randomInt(0, variance);
    }
    if (numberY < yBoundHigh) {
      numberY = yBoundHigh;
    }

    // add coordinates to path
    path.push({ x: numberX, y: numberY });

    // create a new x and y coordinate to check and possibly add to path
    // which is Z units "away" from the previous point. (20 hardcoded prev)
    const distX = Numbers.randomInt(1, 20);
    const distY = Numbers.randomInt(0, 70);
    numberX = Numbers.randomInt(numberX, numberX + distX);
    numberY = Numbers.randomInt(numberY - distY, numberY + distY);

    return true;
  });

  // add the end points to the path and return
  path.push({ x: xBound, y: yBoundLow });
  path.push({ x: -xBound, y: yBoundLow });
  // console.log("PATH", path);
  return path;
}
